// import React, { useState, useEffect } from 'react';
import api from '../../api';
import SideBar from '../SideBar';
import Header from "../Header";
import { useState, useEffect, useRef, useCallback  } from 'react';
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import { useLocation,useNavigate } from 'react-router-dom';

const Employees = () => {
    const [employees, setEmployees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedEmployees, setCheckedEmployees] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [iinFilter, setinnFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no
   
    const navigate = useNavigate();
    const location = useLocation();
    const searchIDofelem = location.search.replace("?","");

    const fetchEmployees = async (offset) => {
        setLoading(true);
        try {
            console.log(location)
            if (searchIDofelem){
                const response = await api.post('/api/v1/bills/employeefilter/', {
                    id:searchIDofelem,
                    is_active : [true,false]
                });
                setEmployees(response.data);
            }else{
                const response = await api.get(`/api/v1/bills/employee/?limit=${limit}&offset=${offset}`);
                setEmployees(prevEmployees => {
                    const newEmployees = response.data.results.filter(newEmployees => 
                        !prevEmployees.some(prevEmployees => prevEmployees.id === newEmployees.id)
                    );
                    return [...prevEmployees, ...newEmployees];
                });
                if (!response.data.next) {
                    setHasMore(false);
                }
        }
        } catch (error) {
            console.error('Error fetching Employees', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    useEffect(() => {
        setEmployees([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
        setCompanies([]);
        fetchEmployees();
    }, [location]);

    useEffect(() => async () => {
        setLoading(true);
        try {
            if (hasMore) {
                fetchEmployees(offset);
            }
            await Promise.all([
                fetchCompanies()
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    }, [offset, hasMore, location]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchEmployeesById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/bills/employeefilter/', {
                        id:searchIDofelem,
                        is_active : [true,false]
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setEmployees(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setEmployees([]);
                setOffset(0);
                setHasMore(true);
                fetchEmployees();
            }
        };

        fetchEmployeesById();
    }, [searchIDofelem]);


    const handleUpdateEmployees = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedEmployees.map(id => 
                api.patch(`/api/v1/bills/employee/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setEmployees([]);
            fetchEmployees();
            setOffset(0);
            setHasMore(true);
            setCheckedEmployees([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} employees`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveEmployees = () => handleUpdateEmployees(false);
    const handleUnarchiveEmployees = () => handleUpdateEmployees(true);
    

    const handleCheckboxChange = (orgId) => {
        setCheckedEmployees(prevOrgs => {
            if (prevOrgs.includes(orgId)) {
                return prevOrgs.filter(id => id !== orgId);
            } else {
                return [...prevOrgs, orgId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allOrgs = employees.map(employee => employee.id);
            setCheckedEmployees(allOrgs);
        } else {
            setCheckedEmployees([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = employees.length > 0 && employees.every(emp => checkedEmployees.includes(emp.id));


    


    const handleReset = () => {
        // Сбрасываем фильтры к начальному состоянию
        // setNameFilter('');
        // fetchEmployees();
        // setinnFilter('');
        // setSelectedOrganization('');
        // setArchivedFilter(false);
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSearch = async () => {
        // console.log('Filters applied:', nameFilter, archivedFilter); // Проверка значений фильтров
        // Фильтрация сотрудников по имени и архивности
        try {
            const response = await api.post('/api/v1/bills/employeefilter/', {
                name: nameFilter.toLowerCase(), 
                iin: iinFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                
                company : organFilter
                 // передайте нужные данные для фильтра
            });
            console.log('Employees fetched:', response.data); // Посмотрим, что приходит с сервера
            // setEmployees(response.data);
            setEmployees(response.data); // Изначально показываем всех сотрудников
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Физические лица</h3>
                                    <div>
                                        <button 
                                            onClick={handleArchiveEmployees} 
                                            className="btn btn-secondary me-2"
                                            disabled={checkedEmployees.length === 0}
                                        >
                                            Архивировать выбранные
                                        </button>
                                        <button 
                                            onClick={handleUnarchiveEmployees} 
                                            className="btn btn-success me-2"
                                            disabled={checkedEmployees.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </button>
                                        <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование сотрудника</label>
                                            <input
                                                type="text"
                                                placeholder="Введите ФИО"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите ИИН"
                                                value={iinFilter}
                                                onChange={e => setinnFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный сотрудник</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                    <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th>
                                            <input 
                                                type="checkbox" 
                                                checked={allChecked}
                                                onChange={handleSelectAllChange}
                                            />
                                        </th>
                                        <th>ФИО физического лица</th>
                                        <th>ИИН</th>
                                        <th>Организация</th>
                                        <th>Архивный</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {employees.map((employee) => (
                                        <tr key={employee.name} >
                                            <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={checkedEmployees.includes(employee.id)}
                                                            onChange={() => handleCheckboxChange(employee.id)}
                                                        />
                                                    </td> 
                                            <td>{employee.name}</td>
                                            <td>{employee.iin}</td>
                                            <td>{employee.company ? companies.find(company => company.id === employee.company)?.name : ''}</td>
                                            <td>{employee.is_active ? 'Нет' : 'Да'}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
