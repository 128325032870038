import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../api";


const ProjectsTable = ({ projects }) => {
    const navigate = useNavigate()
    const [currency, setCurrency] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleRowClick = (counterparty) => {
        navigate(`/projects-edit/${counterparty.id}`)
    }
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    const fetchCurrency = async () => {
        try {
            const response = await api.get('/api/v1/bank/currencies/');
            setCurrency(response.data);
        } catch (error) {
            console.log('Error fetching document types', error)
        }
    }
    
    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchGroups(),
                fetchCompanies(),
                fetchCurrency(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Наименование проекта</th>
                    <th>Родительская группа</th>
                    <th>Организация</th>
                    <th>Дата создания</th>
                    <th>Архивный</th>
                </tr>
            </thead>
            <tbody>
                {projects.map((project) => (
                    <tr key={project.name} onDoubleClick={() => handleRowClick(project)}>
                        <td>{project.name}</td>
                        <td>{project.finmodel ? groups.find(group => group.id === project.finmodel)?.name : ''}</td>
                        <td>{project.company ? companies.find(company => company.id === project.company)?.name : ''}</td>
                        <td>
                            {new Date(project.created).toLocaleString('ru-RU', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZone: 'Asia/Almaty'
                            })}
                        </td>
                        <td>{project.is_active ? 'Нет' : 'Да'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProjectsTable;
