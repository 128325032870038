import { useState, useEffect } from "react";
import api from "../api";
import SideBar from "./SideBar";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';

const CreateUserPage = () => {
    const [formData, setFormData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone_number: '',
        position: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation
        const { username, first_name, last_name, middle_name, phone_number, email, position, password, confirmPassword } = formData;
        const errors = {};
        if (!username) {
            errors.username = 'Username is required';
        }

        if (!position) {
            errors.position = "Position is required"
        }

        if (!email) {
            errors.email = 'Email is required';
        }
        if (!password) {
            errors.password = 'Password is required';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if (!first_name) {
            errors.first_name = 'First name is required';
        }
        if (!last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!middle_name) {
            errors.middle_name = 'Middle name is required';
        }
        if (!phone_number) {
            errors.phone_number = 'Phone number is required'
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        // Submit the form
        try {
            setLoading(true);
            const response = await api.post('/api/v1/worker/', formData);
            console.log('Registration successful:', response.data);
            setLoading(false);
            navigate('/finmodel')
        } catch (error) {
            console.error('Registration failed:', error.response.data);
            // Handle error (e.g., show error message)
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col">
                    <div className="create-user-header mt-3">
                        <h2>Создание пользователя</h2>
                    </div>
                    <div className="create-user-content mt-5">
                        <form className="user-form" onSubmit={handleSubmit}>
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className='form-label'>Логин</label>
                                            <input className="form-control" type="text" name="username" placeholder='Введите ИИН' value={formData.username} onChange={handleInputChange} />
                                            {errors.username && <span>{errors.username}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Фамилия</label>
                                            <input className="form-control" type="text" name="first_name" placeholder='Введите фамилию' value={formData.first_name} onChange={handleInputChange} />
                                            {errors.first_name && <span>{errors.first_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Имя</label>
                                            <input className="form-control" type="text" name="last_name" placeholder='Введите имя' value={formData.last_name} onChange={handleInputChange} />
                                            {errors.last_name && <span>{errors.last_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className="form-label">Отчество</label>
                                            <input className="form-control" type="text" name="middle_name" placeholder='Введите отчество' value={formData.middle_name} onChange={handleInputChange} />
                                            {errors.middle_name && <span>{errors.middle_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Номер телефона</label>
                                            <input className="form-control" type="text" name="phone_number" placeholder='+7 777 77 77 777' value={formData.phone_number} onChange={handleInputChange} />
                                            {errors.phone_number && <span>{errors.phone_number}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className='form-label'>Email</label>
                                            <input className="form-control" type="email" name="email" placeholder='Введите email' value={formData.email} onChange={handleInputChange} />
                                            {errors.email && <span>{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-5'>
                                            <label className='form-label'>Должность</label>
                                            <input className="form-control" type="text" name="position" placeholder='Введите должность' value={formData.position} onChange={handleInputChange} />
                                            {errors.position && <span>{errors.position}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Пароль</label>
                                            <input className="form-control" type="password" name="password" value={formData.password} onChange={handleInputChange} />
                                            {errors.password && <span>{errors.password}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Повторить пароль</label>
                                            <input className="form-control" type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} />
                                            {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateUserPage;