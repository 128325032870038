import React from 'react';
import { Route, Routes, Navigate} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentEdit from './components/payments/PaymentEdit';
import BankAccountsEdit from './components/bankaccounts/BankAccountsEdit';
import PaymentManagement from './components/payments/PaymentManagement';
import CreatePayment from './components/payments/CreatePayment';
import BankAccounts from './components/bankaccounts/BankAccounts';
import CreateBankAccount from './components/bankaccounts/CreateBankAcc';
import CaptionManagement from './components/captions/CaptionManagement';
import CaptionEdit from './components/captions/CaptionEdit';
import CaptionCreate from './components/captions/CaptionCreate';
import Finmodel from './components/Finmodel';
import SalaryManagement from './components/salaries/SalaryManagement.jsx';
import ProjectMonthPage from './components/salaries/ProjectMonthPage.jsx';
import MatrixPage from './components/salaries/MatrixPage.jsx';
import Counterparties from './components/Counterparties/Counterparties.jsx';
import CounterpartiesEdit from './components/Counterparties/CounterpartiesEdit.jsx';
import CounterpartyCreate from './components/Counterparties/CounterpartyCreate.jsx'
import Projects from './components/projects/Projects.jsx'
import Employees from './components/employees/Employee.jsx'
import ProjectEdit from './components/projects/ProjectEdit.jsx'
import ProjectCreate from './components/projects/ProjectCreate.jsx'
import CreateUserPage from './components/CreateUserPage.jsx';
import CreateGroup from './components/roles/CreateGroup.jsx';
import EditGroup from './components/roles/EditGroup.jsx';
import OrganizationPage from './components/organizations/OrganizationPage.jsx';
import BudgetsPage from './components/budgets/BudgetsPage.jsx';
import UserEditPage from './components/UserEditPage.jsx';


function Logout() {
  localStorage.clear()
  return <Navigate to="/login"/>
}

function RegisterAndLogout() {
  localStorage.clear()
  return <RegistrationForm />
}

class App extends React.Component {
  render(){

  
  return (
        <div>
            <Routes>
              <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
              <Route path="/register" element={<RegistrationForm/>}></Route>
              <Route path='/navigation' element={<Navigation/>}></Route>
              <Route path='/logout' element={<Logout/>}></Route>
              <Route path="/login" element={<LoginForm/>}></Route>
              <Route path='*' element={<NotFound/>}></Route>
              <Route path='/edit-payment/:id' element={<ProtectedRoute><PaymentEdit/></ProtectedRoute>}></Route>
              <Route path="/payment-registry" element={<ProtectedRoute><PaymentManagement/></ProtectedRoute>}></Route>
              <Route path="/create-payment" element={<ProtectedRoute><CreatePayment/></ProtectedRoute>}></Route>
              <Route path="/create-bankacc" element={<ProtectedRoute><CreateBankAccount/></ProtectedRoute>}></Route>
              <Route path="/bankacc" element={<ProtectedRoute><BankAccounts/></ProtectedRoute>}></Route>
              <Route path='/edit-bankacc/:id' element={<ProtectedRoute><BankAccountsEdit/></ProtectedRoute>}></Route>
              <Route path='/captions' element={<ProtectedRoute><CaptionManagement/></ProtectedRoute>}></Route>
              <Route path='/caption-edit/:id' element={<ProtectedRoute><CaptionEdit/></ProtectedRoute>}></Route>
              <Route path='/create-caption' element={<ProtectedRoute><CaptionCreate/></ProtectedRoute>}></Route>    
              <Route path='/finmodel' element={<ProtectedRoute><Finmodel/></ProtectedRoute>}></Route>      
              <Route path='/salaries' element={<ProtectedRoute><SalaryManagement/></ProtectedRoute>}></Route>  
              <Route path='/salaries/:month' element={<ProtectedRoute><ProjectMonthPage/></ProtectedRoute>}></Route>
              <Route path='/salaries/:month/:id' element={<ProtectedRoute><MatrixPage/></ProtectedRoute>}></Route>
              <Route path="/counterparty" element={<ProtectedRoute><Counterparties/></ProtectedRoute>}></Route>
              <Route path="/edit-counterparty/:id" element={<ProtectedRoute><CounterpartiesEdit/></ProtectedRoute>}></Route>
              <Route path="/create-counterparty" element={<ProtectedRoute><CounterpartyCreate/></ProtectedRoute>}></Route>
              <Route path="/projects" element={<ProtectedRoute><Projects/></ProtectedRoute>}></Route>
              <Route path="/employees" element={<ProtectedRoute><Employees/></ProtectedRoute>}></Route>
              <Route path="/projects-edit/:id" element={<ProtectedRoute><ProjectEdit/></ProtectedRoute>}></Route>
              <Route path="/create-project" element={<ProtectedRoute><ProjectCreate/></ProtectedRoute>}></Route>
              <Route path="/create-user" element={<ProtectedRoute><CreateUserPage/></ProtectedRoute>}></Route>
              <Route path="/edit-user/:id/" element={<ProtectedRoute><UserEditPage/></ProtectedRoute>}></Route>
              <Route path="/create-group" element={<ProtectedRoute><CreateGroup/></ProtectedRoute>}></Route>
              <Route path="/edit-group/:id/" element={<ProtectedRoute><EditGroup/></ProtectedRoute>}></Route>
              <Route path="/organizations" element={<ProtectedRoute><OrganizationPage/></ProtectedRoute>}></Route>
              <Route path="/budgets" element={<ProtectedRoute><BudgetsPage/></ProtectedRoute>}></Route>
        </Routes>
        </div>
  );
}
};
export default App;
