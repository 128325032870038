import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/loginStyle.css";
import logo from '../assets/pp_logo.png'
import image from '../assets/login_image.png'
import axios from 'axios';
import ReactLoading from 'react-loading';


const LoginForm = () => {
  // State variables for form fields
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // State variable for error messages
  const [errors, setErrors] = useState({});

  // Handle form input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const { username, password } = formData;
    const errors = {};
    if (!username) {
      errors.username = 'Username is required';
    }
    if (!password) {
      errors.password = 'Password is required';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Submit the form
    try {
      setLoading(true);
      const response = await axios.post('https://back.upr.kz/api/v1/auth/', formData, setTimeout(5000));
      console.log('Login successful:', response.data);
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      setLoading(false);
      navigate('/')
      // Handle success (e.g., redirect to dashboard)
    } catch (error) {
      console.error('Login failed:', error.response.data);
      // Handle error (e.g., show error message)
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (<div className="position-absolute top-50 start-50 translate-middle">
    <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    </div>);
}

  return (
    <div className="login_page_con">
      <div className="login_container">
        <div className="login_header">
          <img src={logo} alt="pplogo"></img>
          <Link to="/register" className='btn btn-outline-primary'>Регистрация</Link>
        </div>
        <div className="login_main">
          <h2>Авторизация</h2>
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <label className='form-label'>Логин:</label>
              <input className="form-control" type="text" name="username" value={formData.username} placeholder='Введите ИИН' onChange={handleInputChange} />
              {errors.username && <span>{errors.username}</span>}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Пароль:</label>
              <input className='form-control' type="password" name="password" value={formData.password} placeholder='********' onChange={handleInputChange} />
              {errors.password && <span>{errors.password}</span>}
            </div>
            <button type="submit">Войти</button>
          </form>
        </div>
      </div>
      <div className="image_container">
        <img src={image} alt="login_image"></img>
      </div>
    </div>
  );
};

export default LoginForm;
