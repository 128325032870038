import React, { useState, useEffect } from 'react';
import EmployeesTable from './EmployeesTable';
import api from '../../api';
import SideBar from '../SideBar';
import Header from "../Header";
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 

const Employees = () => {
    // const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [iinFilter, setinnFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no



    const fetchEmployees = async () => {
        try {
            const response = await api.get('/api/v1/bills/employee/?name={employee}');
            // console.log('Employees fetched:', response.data); // Посмотрим, что приходит с сервера
            // setEmployees(response.data);
            setFilteredEmployees(response.data); // Изначально показываем всех сотрудников
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    const handleReset = () => {
        // Сбрасываем фильтры к начальному состоянию
        // setNameFilter('');
        // fetchEmployees();
        // setinnFilter('');
        // setSelectedOrganization('');
        // setArchivedFilter(false);
        window.location.reload();

    };
    const handleSearch = async () => {
        // console.log('Filters applied:', nameFilter, archivedFilter); // Проверка значений фильтров
        // Фильтрация сотрудников по имени и архивности
        try {
            const response = await api.post('/api/v1/bills/employeefilter/', {
                name: nameFilter.toLowerCase(), 
                iin: iinFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                
                company : organFilter
                 // передайте нужные данные для фильтра
            });
            console.log('Employees fetched:', response.data); // Посмотрим, что приходит с сервера
            // setEmployees(response.data);
            setFilteredEmployees(response.data); // Изначально показываем всех сотрудников
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Физические лица</h3>
                                </div>
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование сотрудника</label>
                                            <input
                                                type="text"
                                                placeholder="Введите ФИО"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите ИИН"
                                                value={iinFilter}
                                                onChange={e => setinnFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный сотрудник</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <EmployeesTable employees={filteredEmployees} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
