import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import useFetchData from '../../functions/UprData';
import ProjectProcentTable from './ProjectProcentTable';
import ModalOfProjectList from './ModalOfProjectList';
import ReactLoading from 'react-loading';

const ProjectMonthPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const { month } = useParams();
    const [monthProjects, setMonthProjects] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const fetchMonthProjects = useFetchData(`/api/v1/salary/?month=${month}`, setMonthProjects);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchMonthProjects(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    const showModal = () => {
        setIsOpen(true);
      };
    
    const hideModal = () => {
        setIsOpen(false);
    };

    const handleCreateMatrix = (project) => {
        navigate(`/salaries/${month}/${project}`)
    }

    useEffect(() => {
        fetchData();
    }, [month]);

    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="salary-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Заработные платы</h3>
                                    <div>
                                        <button className='btn btn-primary' onClick={showModal}>+ Создать матрицу</button>
                                        <button className="btn btn-primary" onClick={fetchMonthProjects}>Обновить</button>
                                    </div>
                                </div>
                                <div className="table-responsive" style={{ maxHeight: '75vh', overflowY: 'scroll' }}>
                                    <ProjectProcentTable monthProjects={monthProjects} month={month}/>
                                </div>
                                {loading && <div className="text-center">Loading more...</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalOfProjectList 
                isOpen={isOpen}
                hideModal={hideModal}
                onProjectSelect={handleCreateMatrix}
            />
        </div>
        
    );
}

export default ProjectMonthPage;