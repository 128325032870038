
import SideBar from "./SideBar";
import Header from "./Header";

function Home() {

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
            <SideBar/> 
                <div className="col py-3">
                <h3>Рабочий стол</h3>
                </div>
            </div>
        </div>
    );
}

export default Home;
