import React, { useState, useEffect } from 'react';
import FilterForm from '../FilterForm';
import ProjectsTable from '../projects/ProjectsTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";

const Projects = () => {
    const [filters, setFilters] = useState({});
    const [projects, setProjects] = useState([]);

    const fetchProjects = async () => {
        try {
            const response = await api.get('/api/v1/organization/projects/');
            setProjects(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, [filters]);

    const handleFilterChange = (key, value) => {
        if (key === 'reset') {
            setFilters({});
        } else {
            setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Проекты</h3>
                                    <div>
                                        <Link to='/create-project' className='btn btn-primary me-2'>Добавить</Link>
                                        <button onClick={fetchProjects} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <ProjectsTable projects={projects} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
