// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container_reg {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
.registration_card {
flex: 3 1;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
}

.registration_header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 258px;
    margin-bottom: 30px;
}

.registration_header img {
    width: 200px;
    height: 40px;
}


.registration_form {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.registration_form button {
width: 350px;
padding: 10px 20px;
color: #fff;
border: none;
border-radius: 10px;
cursor: pointer;
background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
}

.form_input {
display: flex;
flex-direction: row;
}

.form_column {
flex: 1 1;
margin-right: 20px;
}

.image_card {
display: flex;
background-color: #5584CE;
flex: 1 1;
justify-content: center;
align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/registerStyle.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;EACf;;AAEF;AACA,SAAO;AACP,uBAAuB;AACvB,mBAAmB;AACnB,aAAa;AACb,sBAAsB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;;AAGA;AACA,aAAa;AACb,sBAAsB;AACtB,uBAAuB;AACvB,mBAAmB;AACnB;;AAEA;AACA,YAAY;AACZ,kBAAkB;AAClB,WAAW;AACX,YAAY;AACZ,mBAAmB;AACnB,eAAe;AACf,6DAA6D;AAC7D;;AAEA;AACA,aAAa;AACb,mBAAmB;AACnB;;AAEA;AACA,SAAO;AACP,kBAAkB;AAClB;;AAEA;AACA,aAAa;AACb,yBAAyB;AACzB,SAAO;AACP,uBAAuB;AACvB,mBAAmB;AACnB","sourcesContent":["\r\n.container_reg {\r\n    display: flex;\r\n    flex-direction: row;\r\n    height: 100vh;\r\n  }\r\n  \r\n.registration_card {\r\nflex: 3;\r\njustify-content: center;\r\nalign-items: center;\r\ndisplay: flex;\r\nflex-direction: column;\r\n}\r\n\r\n.registration_header {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 258px;\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.registration_header img {\r\n    width: 200px;\r\n    height: 40px;\r\n}\r\n\r\n\r\n.registration_form {\r\ndisplay: flex;\r\nflex-direction: column;\r\njustify-content: center;\r\nalign-items: center;\r\n}\r\n\r\n.registration_form button {\r\nwidth: 350px;\r\npadding: 10px 20px;\r\ncolor: #fff;\r\nborder: none;\r\nborder-radius: 10px;\r\ncursor: pointer;\r\nbackground: linear-gradient(135deg, #14ADD6 0%, #384295 100%);\r\n}\r\n\r\n.form_input {\r\ndisplay: flex;\r\nflex-direction: row;\r\n}\r\n\r\n.form_column {\r\nflex: 1;\r\nmargin-right: 20px;\r\n}\r\n\r\n.image_card {\r\ndisplay: flex;\r\nbackground-color: #5584CE;\r\nflex: 1;\r\njustify-content: center;\r\nalign-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
