import React, { useState, useEffect } from 'react';
import FilterForm from '../FilterForm';
import CounterpartiesTable from '../Counterparties/CounterpartiesTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 

const Counterparties = () => {
    const [filters, setFilters] = useState({});
    const [counterparties, setCounterparties] = useState([]);

    const [nameFilter, setNameFilter] = useState('');
    const [iinbinFilter, setinnbinFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no

    const fetchCounterparties = async () => {
        try {
            const response = await api.get('api/v1/bills/counterparty/');
            setCounterparties(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error);
        }
    };

    useEffect(() => {
        fetchCounterparties();
    }, [filters]);

    const handleFilterChange = (key, value) => {
        if (key === 'reset') {
            setFilters({});
        } else {
            setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
        }
    };

    const handleReset = () => {
        window.location.reload();

    };
    const handleSearch = async () => {
        try {
            const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                name: nameFilter.toLowerCase(), 
                iinbin: iinbinFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                company : organFilter
            });
            console.log('Counterparties fetched:', response.data); 
            setCounterparties(response.data); 
        } catch (error) {
            console.error('Error fetching Counterparties:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Контрагенты</h3></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование контрагента</label>
                                            <input
                                                type="text"
                                                placeholder="Введите контрагента"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>БИН/ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите БИН/ИИН"
                                                value={iinbinFilter}
                                                onChange={e => setinnbinFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <Link to='/create-counterparty' className='btn btn-primary me-2'>Добавить</Link>
                                        <button onClick={fetchCounterparties} className="btn btn-primary">Обновить</button>
                                    </div></div>
                                
                                <CounterpartiesTable counterparties={counterparties} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Counterparties;
