import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../api";


const BankAccountsTable = ({ bankaccs }) => {
    const navigate = useNavigate()
    const [counterparties, setCounterparties] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleRowClick = (bankacc) => {
        navigate(`/edit-bankacc/${bankacc.id}`)
    }
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }
    const fetchCounterparty = async () => {
        try {
            const res = await api.get('api/v1/bills/counterparty/');
            setCounterparties(res.data);
        } catch (error) {
            console.error("Error fetching counterpaties", error)
        }
    }

    const fetchCurrency = async () => {
        try {
            const response = await api.get('/api/v1/bank/currencies/');
            setCurrency(response.data);
        } catch (error) {
            console.log('Error fetching document types', error)
        }
    }
    
    const fetchBanks = async () => {
        try {
            const response = await api.get('/api/v1/bank/');
            setBanks(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCounterparty(),
                fetchBanks(),
                fetchCompanies(),
                fetchCurrency(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Название банковского счета</th>
                    <th>Номер банковского счета</th>
                    <th>Организация</th>
                    <th>Код</th>
                    <th>Вид счета</th>
                    <th>Банк</th>
                    <th>Валюта</th>
                    <th>Владелец(Организация)</th>
                    <th>Владелец(Контрагент)</th>
                    <th>Архивный</th>
                </tr>
            </thead>
            <tbody>
                {bankaccs.map((bankacc) => (
                    <tr key={bankacc.name} onDoubleClick={() => handleRowClick(bankacc)}>
                        <td>{bankacc.name}</td>
                        <td>{bankacc.account_number}</td>
                        <td>{bankacc.company ? companies.find(company => company.id === bankacc.company)?.name : ''}</td>
                        <td>{bankacc.code}</td>
                        <td>{bankacc.account_type}</td>
                        <td>{bankacc.bank ? banks.find(bankaccount => bankaccount.id === bankacc.bank)?.name : ''}</td>
                        <td>{bankacc.currency ? currency.find(currency => currency.id === bankacc.currency)?.name : ''}</td>
                        <td>{bankacc.owner_organization ? companies.find(company => company.id === bankacc.owner_organization)?.name : ''}</td>
                        <td>{bankacc.owner_counterparty ? counterparties.find(counterparty => counterparty.id === bankacc.owner_counterparty)?.name : ''}</td>
                        <td>{bankacc ? (bankacc.is_active ? 'Нет' : 'Да') : ''}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default BankAccountsTable;
