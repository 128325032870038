import SideBar from '../SideBar';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from "../../api";
import CaptionTable from "./CaptionTable";
import useFetchData from "../../functions/UprData";
import Header from "../Header";


const CaptionManagement = () => {
    const [captions, setCaptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [caption_types, setCaptionTypes] = useState([]);
    const [companies, setCompanies] = useState([]);

    const fetchCaptions = async () => {
        try {
            const response = await api.get(`api/v1/bills/caption/`);
            setCaptions(response.data)
        } catch (error) {
            console.error('Error fetching captions', error);
        }
    }
    const fetchCompanies = useFetchData('api/v1/organization/', setCompanies)
    const fetchCaptionTypes = useFetchData('api/v1/bills/caption-types/', setCaptionTypes)
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCaptions(),
                fetchCaptionTypes(),
                fetchCompanies(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Статьи</h3>
                                    <div>
                                        <Link to='/create-caption' className='btn btn-primary me-2'>Добавить статью</Link>
                                        <button className="btn btn-primary" onClick={fetchCaptions}>Обновить</button>
                                    </div>
                                </div>
                                <div className="table-responsive" style={{ maxHeight: '75vh', overflowY: 'hidden' }}>
                                    <CaptionTable captions={captions} caption_types={caption_types} companies={companies}/>
                                </div>
                                {loading && <div className="text-center">Loading more...</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaptionManagement;