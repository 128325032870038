import SideBar from '../SideBar';
import { useState, useEffect, useRef, useCallback  } from 'react';
import api from '../../api';
import ReactLoading from 'react-loading';
import Header from "../Header";
import { Modal, Form, Button } from 'react-bootstrap';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect';
import APISearchableSelect from '../payments/SearchableSelect';


const BudgetsPage = () => {
    
    const [budgets, setBudgets] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);
    const [checkedBudgets, setCheckedBudgets] = useState([]);
    const [newBudget, setNewBudget] = useState({
        'name': '',
        'project': '',
        'period_start': '',
        'period_end': '',
        'user_responsible': ''
    })
    const limit = 15;
    const [newUserResponsible, setNewUserResponsible] = useState({});
    const [isOpen, setIsOpen] = useState(false);


    const [nameFilter, setNameFilter] = useState('');
    const [projectFilter, setSelectedProject] = useState('');
    const [archivedFilterYES, setArchivedFilterYES] = useState(false);
    const [archivedFilterNO, setArchivedFilterNO] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [openFilter, setOpenedFilter] = useState(false);
    const [closeFilter, setCLosedFilter] = useState(false);
    const handleReset = () => {
        window.location.reload();
    };
    const handleSearch = async () => {
        console.log('Filters applied:', nameFilter, projectFilter,openFilter); 
        try {
            const response = await api.post('api/v1/budgets/budgetfilter/', {
                name: nameFilter.toLowerCase(), 
                is_active : [!archivedFilterYES,archivedFilterNO], 
                project : projectFilter,
                is_open: [!openFilter,closeFilter],
                startDate:startDateFilter,
                endDate:endDateFilter
            });
            console.log('budget fetched:', response.data); 
            setBudgets(response.data); // Изначально показываем всех сотрудников
        } catch (error) {
            console.error('Error fetching budgets:', error);
        }
    };

    const [errors, setErrors] = useState({})
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isEditUserOpen, setIsEditUserOpen] = useState(false);
    const [editingBudget, setEditingBudget] = useState({});

    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };

    const showEditModal = (budget) => {
        setEditingBudget(budget);
        setIsEditOpen(true);
    };
    const hideEditModal = () => {
        setIsEditOpen(false);
    };

    const showEditUserModal = () => {
        setIsEditUserOpen(true);
    };

    const hideEditUserModal = () => {
        setIsEditUserOpen(false);
    };

    const handleEditBudgetChange = (e) => {
        const { name, value } = e.target;
        setEditingBudget({
            ...editingBudget,
            [name]: value
        });
    }

    const formatDate = (date) => {
        if (!date) return ''; // Handle empty or undefined date

        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (regex.test(date)) {
            return date; // Not in YYYY-MM-DD format
        }
        // Check if the date is in "DD.MM.YYYY" format and split it
        const [day, month, year] = date.split('.');
    
        // Return a string in "YYYY-MM-DD" format
        const formattedDate = `${year}-${month}-${day}`;
    
        const d = new Date(formattedDate);
        if (isNaN(d.getTime())) return ''; // If invalid date, return empty string
    
        return formattedDate;
    };

    const handleBudgetUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {
                "id": editingBudget.id,
                "name": editingBudget.name,
                "period_end": formatDate(editingBudget.period_end),
                "period_start": formatDate(editingBudget.period_start),
                "project": editingBudget.project.id,
                "user_responsible": editingBudget.user_responsible.id ? editingBudget.user_responsible.id : editingBudget.user_responsible
            }
            const response = await api.patch(`api/v1/budgets/update/${editingBudget.id}`, data);
            console.log(response.data);
            setBudgets([]);
            setOffset(0);
            hideEditModal();
            setEditingBudget([]);
            setHasMore(true);
        } catch (error) {
            console.log(error);
        } finally {
            setCheckedBudgets([]);
            setLoading(false);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewBudget({
            ...newBudget,
            [name]: value
        });
    };

    const fetchBudgets = useCallback(async(offset) => {
        try {
            setLoading(true);
            const response = await api.get(`api/v1/budgets/?limit=${limit}&offset=${offset}`);
            setBudgets(prevBudgets => {
                const newBudgets = response.data.results.filter(newBudget =>
                    !prevBudgets.some(prevBudget => prevBudget.id === newBudget.id)
                );
                return [...prevBudgets, ...newBudgets];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setBudgets([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
    }, []);

    useEffect(() => {
        if (hasMore && !loading) {
            fetchBudgets(offset);
        }
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    const handleClosing = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {'budget_ids': checkedBudgets}
            const response = await api.patch('api/v1/budgets/close/', data);
            console.log(response.data);
            setBudgets([]);
            setOffset(0);
            setHasMore(true);
        } catch (error) {
            console.log(error);
        } finally {
            setCheckedBudgets([]);
            setLoading(false);
        }
    };

    const handleOpening = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {'budget_ids': checkedBudgets}
            const response = await api.patch('api/v1/budgets/open/', data);
            console.log(response.data);
            setBudgets([]);
            setOffset(0);
            setHasMore(true);
        } catch (error) {
            console.log(error);
        } finally {
            setCheckedBudgets([]);
            setLoading(false);
        }
    };

    const handleChangeResponsible = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {'budgets_Id': checkedBudgets, 'user_responsible': newUserResponsible}
            const response = await api.patch('api/v1/budgets/user/responsible/', data);
            console.log(response.data);
            setBudgets([]);
            setOffset(0);
            setHasMore(true);
        } catch (error) {
            console.log(error);
        } finally {
            hideEditUserModal();
            setNewUserResponsible({});
            setCheckedBudgets([]);
            setLoading(false);
        }
    };

    const handleArchiving = async (is_active) => {
        setLoading(true);
        try {
            const data = {'budgets': checkedBudgets, 'activity': is_active}
            const response = await api.patch('api/v1/budgets/is_active/update/', data);
            console.log(response.data);
            setBudgets([]);
            setOffset(0);
            setHasMore(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setCheckedBudgets([]);
        }
    }

    const handleArchiveBudgets = (e) => {
        e.preventDefault();
        handleArchiving(false)
    };
    const handleUnarchiveBudgets = (e) => {
        e.preventDefault();
        handleArchiving(true);
    }

    const handleCheckboxChange = (budget) => {
        setCheckedBudgets(prevBudgets => {
            if (prevBudgets.includes(budget)) {
                return prevBudgets.filter(id => id !== budget);
            } else {
                return [...prevBudgets, budget];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allBudgets = budgets.map(budget => budget.id);
            setCheckedBudgets(allBudgets);
        } else {
            setCheckedBudgets([]);
        }
    };

    const allChecked = budgets.length > 0 && budgets.every(budget => checkedBudgets.includes(budget.id));

    const handleModalSave = async () => {
        try {

            const {name, project, user_responsible, period_start, period_end} = newBudget;
            const errors = {};
            if (!name){
                errors.name = 'Название бюджета обязательна'
            }
            if (!project){
                errors.project = 'Проект бюджета обязательна'
            }
            if (!user_responsible){
                errors.user_responsible = 'Ответсвенный бюджета обязателен'
            }
            if (!period_end || !period_start){
                errors.period = 'Период бюджета обязательна'
            }

            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }

            const response = await api.post('/api/v1/budgets/create/', newBudget);

            if (response.status === 201) {
                setBudgets([]);
                setOffset(0);
                setHasMore(true);
                hideModal();
                setNewBudget([]);
            } else {
                // Handle error response
                console.error('Failed to create new budget', response);
            }
        } catch (error) {
            console.error('An error occurred while creating new budget', error);
        }
    };

    const handleCopyBudget = async () => {
        setLoading(true);
        try {
            const data = {'budgetIds': checkedBudgets}
            const response = await api.post('api/v1/budgets/copy/', data);
            console.log(response.data);
            setBudgets([]);
            setOffset(0);
            setHasMore(true);
            setCheckedBudgets([]);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    
    const handleOpen = async(budget) => {
        try {
            const response = await api.patch(`api/v1/budgets/update/${budget.id}`, {'is_open': !budget.is_open})
            console.log(response.data);
            if (response.status === 200) {
                setBudgets((prevBudgets) => 
                    prevBudgets.map((b) => 
                        b.id === budget.id ? { ...b, is_open: !b.is_open } : b
                    )
                );
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="budgets-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3"><h3>Бюджеты</h3></div>
                            
                            <div className="mb-3 p-3 border rounded" style={{display:'block'}} >
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование бюджета</label>
                                            <input
                                                type="text"
                                                placeholder="Введите название бюджета"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Проект</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/projects/search/"
                                            value={projectFilter}
                                            onChange={(value) => setSelectedProject(value)}
                                            placeholder="Выберите проект"
                                        />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Период действия</label>
                                            <div className="d-flex">
                                                <input
                                                type="date"
                                                value={startDateFilter}
                                                onChange={e => setStartDateFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                                <span className="mx-2">—</span>
                                                <input
                                                type="date"
                                                value={endDateFilter}
                                                onChange={e => setEndDateFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label >Архивный бюджет</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckYES"
                                                    checked={archivedFilterYES}
                                                    onChange={e => setArchivedFilterYES(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheck">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNO"
                                                    checked={archivedFilterNO}
                                                    onChange={e => setArchivedFilterNO(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheck">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label>Бюджет закрыт</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="openedCheck"
                                                    checked={openFilter}
                                                    onChange={e => setOpenedFilter(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheck">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="closedCheck"
                                                    checked={closeFilter}
                                                    onChange={e => setCLosedFilter(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheck">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-3">                               
                                    <div>
                                        <button className='btn btn-primary' onClick={showModal}>+ Добавить бюджет</button>
                                        <button className={checkedBudgets.length ? "btn btn-secondary dropdown-toggle" : 'd-none'} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Действие
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><span class="dropdown-item" onClick={handleOpening}>Открыть редактирование</span></li>
                                            <li><span class="dropdown-item" onClick={handleClosing}>Закрыть редактирование</span></li>
                                            <li><span class="dropdown-item" onClick={showEditUserModal}>Поменять ответсвенного</span></li>
                                            <li><span class="dropdown-item" onClick={handleCopyBudget}>Копировать бюджет</span></li>
                                            <li><span class="dropdown-item" onClick={handleArchiveBudgets}>Архивировать бюджет</span></li>
                                            <li><span class="dropdown-item" onClick={handleUnarchiveBudgets}>Разархивировать бюджет</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                    <table className="table">
                                        <thead className="table-light">
                                            <tr>
                                                <th><input 
                                                        type="checkbox" 
                                                        checked={allChecked}
                                                        onChange={handleSelectAllChange}
                                                    /></th>
                                                <th></th>
                                                <th className='text-start'>Наименование бюджета</th>
                                                <th>Проект</th>
                                                <th>Период</th>
                                                <th>Ответсвенный</th>
                                                <th>Дата создания</th>
                                                <th>Архивный</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {budgets.map((budget) => (
                                                <tr key={budget.id} className={!budget.is_open ? 'table-secondary' : ''} onDoubleClick={() => {if (budget.is_open){showEditModal(budget)}}}>
                                                    <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={checkedBudgets.includes(budget.id)}
                                                            onChange={() => handleCheckboxChange(budget.id)}
                                                            /></td>
                                                    <td><i className={!budget.is_open ? 'bi bi-lock-fill' : 'bi bi-unlock-fill'} onClick={() => handleOpen(budget)}></i></td>
                                                    <td className='text-start'>{budget ? budget.name : ''}</td>
                                                    <td>{budget.project ? budget.project.name : ''}</td>
                                                    <td>{budget ? `${budget.period_start} - ${budget.period_end}` : ''}</td>
                                                    <td>{budget.user_responsible ? `${budget.user_responsible.first_name} ${budget.user_responsible.last_name}` : ''}</td>
                                                    <td>{budget ? budget.created : ''}</td>
                                                    <td>{budget ? (budget.is_active ? 'Нет' : 'Да') : ''}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={hideModal} dialogClassName={"modal-dialog-centered"}>
                <Modal.Header className='justify-content-center'>
                    <Modal.Title>Добавление бюджета</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNewBudget">
                            <Form.Label>Наименование бюджета</Form.Label>
                            <Form.Control
                                className='form-control-sm'
                                type="text"
                                name="name"
                                value={newBudget.name}
                                onChange={handleChange}
                                placeholder="Введите название бюджета"
                            />
                            {errors.name && <span>{errors.name}</span>}
                        </Form.Group>
                        <Form.Group controlId="formCaptionType">
                            <Form.Label>Проект</Form.Label>
                            <APISearchableSelect
                                        endpoint="/api/v1/organization/projects/search/"
                                        value={newBudget.project} 
                                        onChange={(value) => handleChange({target:  { name: "project", value } })}
                                        placeholder="Выберите проект"
                                    />
                            {errors.project && <span>{errors.project}</span>}

                        </Form.Group>
                        <Form.Group controlId="formNewBudget">
                            <Form.Label>Период действия</Form.Label>
                            <div className='d-flex justify-content-center align-items-center'>
                            <Form.Control
                                className='form-control-sm'
                                type="date"
                                name="period_start"
                                value={newBudget.period_start}
                                onChange={handleChange}
                            />
                            <i class="bi bi-dash-lg"></i>
                            <Form.Control
                                className='form-control-sm'
                                type="date"
                                name="period_end"
                                value={newBudget.period_end}
                                onChange={handleChange}
                            />
                            </div>
                            {errors.period && <span>{errors.period}</span>}
                        </Form.Group>
                        <Form.Group controlId="formCaptionType">
                            <Form.Label>Ответсвенный</Form.Label>
                            <APISearchableSelect
                                        endpoint="/api/v1/user/search/"
                                        value={newBudget.user_responsible} 
                                        onChange={(value) => handleChange({target:  { name: "user_responsible", value } })}
                                        placeholder="Выберите ответственного"
                                        displayKey='full_name'
                                    />
                            {errors.user_responsible && <span>{errors.user_responsible}</span>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='justify-content-evenly'>
                    <Button variant="secondary" onClick={hideModal}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isEditOpen} onHide={hideEditModal} dialogClassName={"modal-dialog-centered"}>
            <Modal.Header className='justify-content-center'>
                    <Modal.Title>Редактирование бюджета</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNewBudget">
                            <Form.Label>Наименование бюджета</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={editingBudget.name}
                                onChange={handleEditBudgetChange}
                                placeholder="Введите название бюджета"
                            />
                        </Form.Group>
                        <Form.Group controlId="formCaptionType">
                            <Form.Label>Проект</Form.Label>
                            <APISearchableSelect
                                        endpoint="/api/v1/organization/projects/search/"
                                        value={editingBudget?.project} 
                                        onChange={(value) => handleEditBudgetChange({target:  { name: "project", value } })}
                                        placeholder="Выберите проект"
                                        disabled={true}
                                    />
                        </Form.Group>
                        <Form.Group controlId="formNewBudget">
                            <Form.Label>Период действия</Form.Label>
                            <div className='d-flex justify-content-center align-items-center'>
                            <Form.Control
                                type="date"
                                name="period_start"
                                value={editingBudget.period_start ? formatDate(editingBudget.period_start) : ''}
                                onChange={handleEditBudgetChange}
                            />
                            <i class="bi bi-dash-lg"></i>
                            <Form.Control
                                type="date"
                                name="period_end"
                                value={editingBudget.period_end ? formatDate(editingBudget.period_end) : ''}
                                onChange={handleEditBudgetChange}
                            />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formCaptionType">
                            <Form.Label>Ответсвенный</Form.Label>
                            <APISearchableSelect
                                        endpoint="/api/v1/user/search/"
                                        value={editingBudget?.user_responsible} 
                                        onChange={(value) => handleEditBudgetChange({target:  { name: "user_responsible", value } })}
                                        placeholder="Выберите ответственного"
                                        displayKey='full_name'
                                    />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='justify-content-evenly'>
                    <Button variant="secondary" onClick={hideEditModal}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleBudgetUpdate}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={isEditUserOpen} onHide={hideEditUserModal} dialogClassName={"modal-dialog-centered"}>
                <Modal.Header className='justify-content-center'>
                    <Modal.Title>Смена ответсвенного</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formCaptionType">
                            <Form.Label>Ответсвенный</Form.Label>
                            <APISearchableSelect
                                        endpoint="/api/v1/user/search/"
                                        value={editingBudget.user_responsible ? editingBudget.user_responsible.full_name : ''} 
                                        onChange={(value) => setNewUserResponsible(value)}
                                        placeholder="Выберите ответственного"
                                        displayKey='full_name'
                                    />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='justify-content-evenly'>
                    <Button variant="secondary" onClick={hideEditUserModal}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleChangeResponsible}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </>
    );
}

export default BudgetsPage;