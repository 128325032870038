import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import api from "../../api";
import CaptionEdit from "./CaptionEdit";


const CaptionTable = ({captions, caption_types, companies}) => {
    const navigate = useNavigate();

    const handleRowClick = (caption) => {
        navigate(`/caption-edit/${caption.id}`)
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Наименование</th>
                    <th>Описание</th>
                    <th>Тип Статьи</th>
                    <th>Родитель</th>
                    <th>Организация</th>
                </tr>
            </thead>
            <tbody>
                {captions.map((caption) => (
                    <tr key={caption.id} onDoubleClick={() => handleRowClick(caption)}>
                        <td>{caption.name}</td>
                        <td>{caption.description}</td>
                        <td>{caption.type_of_caption ? caption_types.find(caption_type => caption_type.id === caption.type_of_caption)?.name : ''}</td>
                        <td>{caption.parent_caption ? captions.find(captiont => captiont.id === caption.parent_caption)?.name : ''}</td>
                        <td>{caption.company ? companies.find(company => company.id === caption.company)?.name : ''}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default CaptionTable;