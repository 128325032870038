import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/homePageStyle.css";

const FilterForm = ({ filters, onChange }) => {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <div className="filter-form">
            <h5>Фильтры</h5>
            <div className="form-group mb-3">
                <label>Дата платежа</label>
                <div className="d-flex">
                    <input type="date" name="startDate" className="form-control me-2" onChange={handleInputChange} placeholder="От" />
                    <input type="date" name="endDate" className="form-control" onChange={handleInputChange} placeholder="До" />
                </div>
            </div>
            <div className="form-group mb-3">
                <label>Статья</label>
                <select name="article" className="form-select" onChange={handleInputChange}>
                    <option value="">Выберите название статьи</option>
                    {/* Add options here */}
                </select>
            </div>
            <div className="form-group mb-3">
                <label>Проект</label>
                <select name="project" className="form-select" onChange={handleInputChange}>
                    <option value="">Выберите название проекта</option>
                    {/* Add options here */}
                </select>
            </div>
            <div className="form-group mb-3">
                <label>Контрагент</label>
                <select name="contragent" className="form-select" onChange={handleInputChange}>
                    <option value="">Выберите контрагента</option>
                    {/* Add options here */}
                </select>
            </div>
            <div className="form-group mb-3">
                <label>Сумма платежа</label>
                <div className="d-flex">
                    <input type="number" name="minAmount" className="form-control me-2" placeholder="От" onChange={handleInputChange} />
                    <input type="number" name="maxAmount" className="form-control" placeholder="До" onChange={handleInputChange} />
                </div>
            </div>
            <div className="form-group mb-3">
                <label>Банковский счет</label>
                <select name="bankAccount" className="form-select" onChange={handleInputChange}>
                    <option value="">Выберите банковский счет</option>
                    {/* Add options here */}
                </select>
            </div>
            <div className="form-group mb-3">
                <label>Назначение платежа</label>
                <input type="text" name="paymentPurpose" className="form-control" onChange={handleInputChange} />
            </div>
            <div className="form-group mb-3">
                <label>Примечание</label>
                <input type="text" name="note" className="form-control" onChange={handleInputChange} />
            </div>
            <div className="form-group mb-3">
                <label>Организация</label>
                <select name="organization" className="form-select" onChange={handleInputChange}>
                    <option value="">Выберите организацию</option>
                    {/* Add options here */}
                </select>
            </div>
            <div className="form-group mb-3">
                <label>Приоритет документа</label>
                <div className="form-check">
                    <input type="checkbox" name="priority" value="Без приоритета" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Без приоритета</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" name="priority" value="Срочный" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Срочный</label>
                </div>
            </div>
            <div className="form-group mb-3">
                <label>Статус оплаты</label>
                <div className="form-check">
                    <input type="checkbox" name="paymentStatus" value="Планируемый" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Планируемый</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" name="paymentStatus" value="Отправлен на оплату" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Отправлен на оплату</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" name="paymentStatus" value="Не согласован" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Не согласован</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" name="paymentStatus" value="Отложен" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Отложен</label>
                </div>
            </div>
            <div className="form-group mb-3">
                <label>Направление</label>
                <div className="form-check">
                    <input type="checkbox" name="direction" value="Доход" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Доход</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" name="direction" value="Расход" className="form-check-input" onChange={handleInputChange} />
                    <label className="form-check-label">Расход</label>
                </div>
            </div>
            <button className="btn btn-secondary" onClick={() => onChange('reset', {})}>Сбросить</button>
        </div>
    );
};

export default FilterForm;
