import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "../styles/header.css";
import logo from '../assets/pp_logo.png'
import avatar from '../assets/avatar.png'
import {jwtDecode} from 'jwt-decode';
import api from "../api";
import 'bootstrap-icons/font/bootstrap-icons.css';

const Header = () => {
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);

    const handleProfileClick = () => {
        setIsProfileOpen(!isProfileOpen);
        fetchGroups();
    };

    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching groups', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserData(decodedToken);
        }

        setLoading(false);
    }, []);

    if (loading) {
        return <div><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</div>; // Display a loading indicator while fetching data
    }

    // Фильтрация групп, в которых состоит текущий пользователь
    const userGroups = groups.filter(group => 
        group.user.some(user => user.id === userData.user_id)
    );

    return (
        <header className="header d-flex justify-content-between align-items-center p-3 bg-white border-bottom shadow-sm">
            <div className="d-flex align-items-center">
                <img src={logo} alt="Logo" className="logo me-2" />
            </div>
            <div className="position-relative">
                <button 
                    onClick={handleProfileClick} 
                    className="btn btn-link text-decoration-none text-dark"
                >
                    <i class="bi bi-bell-fill"></i>
                    <img src={avatar} alt="User Avatar" className="avatar me-2" />
                    <span>{userData.first_name + ' ' + userData.last_name}</span>
                    <i class="bi bi-chevron-down"></i>
                </button>
                {isProfileOpen && (
                    <div className="profile-dropdown position-absolute end-0 mt-2 p-3 bg-white border rounded shadow-sm">
                        <div className="d-flex align-items-center mb-2">
                            <img src={avatar} alt="User Avatar" className="avatar me-2" />
                            <div>
                                <p className="mb-0">{userData.first_name + ' ' + userData.last_name}</p>
                                <p className="mb-0">{userData.email}</p>
                                <button className="btn btn-sm btn-secondary mt-1">Изменить</button>
                            </div>
                        </div>
                        <p>Фин. модели:</p>
                        <ul className="list-unstyled">
                            {userGroups.map(group => (
                                <li key={group.id}>
                                    <i className="bi bi-gear-fill"></i>
                                    <Link to={`/finmodel`} className="text-decoration-none text-dark">
                                        {group.name}
                                    </Link>
                                </li>
                            ))}
                            <li><Link to="/finmodel" className="text-decoration-none text-dark">+ Добавить фин. модель</Link></li>
                        </ul>
                        <hr />
                        <button className="btn btn-sm btn-outline-secondary">
                            <Link to="/logout" className="text-decoration-none text-dark">Выйти</Link>
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
