import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import api from "../../api";


export const CaptionForm = ({captions, setCaptions, param, index, handleSelectChange, caption_types}) => {
    const [showModal, setShowModal] = useState(false);
    const [newCaption, setNewCaption] = useState(
        {
            name: '',
            description: '',
            parent_caption: '',
            type_of_caption: '',
        }
    );
    const handleModalClose = () => {
        setShowModal(false);
        setNewCaption([]);
    };

    const handleModalSave = async () => {
        try {
            const response = await api.post('/api/v1/bills/caption-create/', newCaption);

            if (response.status === 201) {
                const newCaption = response.data;
                setCaptions([...captions, newCaption]);
                handleModalClose();
                setNewCaption([])
            } else {
                // Handle error response
                console.error('Failed to create new caption', response);
            }
        } catch (error) {
            console.error('An error occurred while creating new caption', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCaption({
          ...newCaption,
          [name]: value
        });
      };

    return (
        <>
        <div className="col-md-4">
            <label className="form-label">Статья</label>
            <select
                className="form-select form-select-sm"
                name="caption"
                value={param.caption || ''}
                onChange={(e) => {
                    handleSelectChange(index, e.target);
                    if (e.target.value === 'create-new') {
                        console.log('Creating new caption...');
                        setShowModal(true);
                    }
                }}
            >
                <option value={param.caption ? param.caption.id : ''}>
                    {param.caption ? captions.find(caption => caption.id === param.caption.id)?.name : 'Выберите статью'}
                </option>
                {captions.map((caption) => (
                    <option key={caption.id} value={caption.id}>
                        {caption.name}
                    </option>
                ))}
                <option value="create-new">Создать новую статью...</option>
            </select>
        </div>

        <Modal show={showModal} onHide={handleModalClose} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
                <Modal.Header closeButton>
                    <Modal.Title>Создать новую статью</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formNewCaption">
                            <Form.Label>Название новой статьи</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newCaption.name}
                                onChange={handleChange}
                                placeholder="Введите название"
                            />
                        </Form.Group>
                        <Form.Group controlId="formCaptionType">
                            <Form.Label>Тип статьи</Form.Label>
                            <Form.Select
                                name="type_of_caption"
                                value={newCaption.type_of_caption || ''}
                                onChange={handleChange}
                            >
                                <option value={newCaption.type_of_caption ? newCaption.type_of_caption : ''}>
                                    {newCaption.type_of_caption ? caption_types.find(caption_type => caption_type.id === newCaption.type_of_caption)?.name : 'Выберите тип статьи...'}
                                </option>
                                {caption_types.map((caption_type) => (
                                        <option key={caption_type.id} value={caption_type.id}>
                                            {caption_type.name}
                                        </option>
                                    ))}
                                </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Родительская статья</Form.Label>
                            <Form.Select
                                name="parent_caption"
                                value={newCaption.parent_caption || ''}
                                onChange={handleChange}
                            >
                                <option value={newCaption.parent_caption ? newCaption.parent_caption : ''}>
                                    {newCaption.parent_caption ? captions.find(caption => caption.id === newCaption.parent_caption)?.name : 'Выберите статью...'}
                                </option>
                                {captions.map((caption) => (
                                        <option key={caption.id} value={caption.id}>
                                            {caption.name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Описание</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={newCaption.description}
                                placeholder="Введите описание"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
    </>
    )
}

