import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import "../../styles/searchSelect.css";

const APIMultipleSearchableSelect = ({ endpoint, value = [], onChange, placeholder, displayKey = 'name', valueKey = 'id' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(Array.isArray(value) ? value : []); // Ensure it's an array
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const searchOptions = async (term) => {
    setLoading(true);
    try {
      if (selectedOptions.length >0 && term===''){
        setOptions(selectedOptions);
      }
      else{
        const response = await api.get(`${endpoint}?search=${term}`);
        setOptions(response.data);
      }
     
    } catch (error) {
      console.error('Error searching options:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        searchOptions(searchTerm);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else if (isOpen) {
      searchOptions('');
    } else {
      setOptions([]);
    }
  }, [searchTerm, isOpen]);

  const handleSelect = (option) => {
    const optionExists = selectedOptions.some(
      (selected) => JSON.stringify(selected) === JSON.stringify(option)
    );

    let updatedSelectedOptions;
    if (optionExists) {
      // Deselect option
      updatedSelectedOptions = selectedOptions.filter(
        (selected) => JSON.stringify(selected) !== JSON.stringify(option)
      );
    } else {
      // Select new option
      updatedSelectedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedSelectedOptions);
    onChange(updatedSelectedOptions.map((opt) => opt[valueKey]));
    setSearchTerm('');
  };

  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      searchOptions('');
    }
  };

  return (
    <div className="searchable-select" ref={wrapperRef}>
      <div 
        className="form-select form-select-sm d-flex justify-content-between align-items-center" 
        onClick={handleToggleDropdown}
      >
        <span>
          {selectedOptions.length > 0
            ? selectedOptions.map((opt) => opt[displayKey]).join(', ')
            : placeholder}
        </span>
      </div>
      {isOpen && (
        <div className="select-dropdown">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <ul className="list-group">
              {options.map((option) => (
                <li
                  key={option[valueKey]}
                  className="list-group-item"
                  onClick={() => handleSelect(option)}
                >
                  {option[displayKey]}
                  {selectedOptions.some(
                    (selected) => JSON.stringify(selected) === JSON.stringify(option)
                  ) && (
                    <span className="red-x">✖</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default APIMultipleSearchableSelect;
