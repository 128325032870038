import { useState, useEffect } from "react";
import api from "../api";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const UserListTable = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const fetchUsers = async () => {
        try {
            const response = await api.get('/api/v1/users/');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users', error)
        }
    }

    const handleRowClick = (user) => {
        navigate(`/edit-user/${user.id}/`)
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <>  
            <div className="d-flex mb-4 mt-2">
                <Link to='/create-user' className='btn btn-primary'>+ Добавить пользователя</Link>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Отчество</th>
                        <th>Почта</th>
                        <th>Телефон</th>
                        <th>Должность</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.username} onDoubleClick={() => {handleRowClick(user)}}>
                            <td>{user.username}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.middle_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>{user.position ? user.position.name : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default UserListTable;
