import SideBar from '../SideBar';
import { Link } from 'react-router-dom';
import api from "../../api";
import useFetchData from "../../functions/UprData";
import Header from "../Header";
import { useState, useEffect, useRef, useCallback  } from 'react';
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import { useLocation, useNavigate} from 'react-router-dom';

const CaptionManagement = () => {
    const [captions, setCaptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [caption_types, setCaptionTypes] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 15;
    const [checkedCaptions, setCheckedCaptions] = useState([]);

    const handleRowClick = (caption) => {
        navigate(`/caption-edit/${caption.id}`)}
    const [nameFilter, setNameFilter] = useState('');
    const [captiontype, setselectedcaptiontype] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?","");

    const fetchCaptions = async () => {
        try {
            console.log(location)
            if (searchIDofelem){
                const response = await api.post('/api/v1/bills/captionfilter/', {
                    id:searchIDofelem
                });
                setCaptions(response.data);
            }else{
                const response = await api.get(`api/v1/bills/caption/?limit=${limit}&offset=${offset}`);
            setCaptions(prevCaptions => {
                const newCaption = response.data.results.filter(newCaption => 
                    !prevCaptions.some(prevCaptions => prevCaptions.id === newCaption.id)
                );
                return [...prevCaptions, ...newCaption];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
            }
        } catch (error) {
            console.error('Error fetching captions', error);
        }}

    const fetchCompanies = useFetchData('api/v1/organization/', setCompanies)
    const fetchCaptionTypes = useFetchData('api/v1/bills/caption-types/', setCaptionTypes)
    useEffect(() => {
        setCaptions([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
        setCompanies([]);
        setCaptionTypes([]);
        fetchCaptions();
    }, [location]);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCaptionTypes(),
                fetchCompanies(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => async () => {
        if (hasMore) {
            fetchCaptions(offset);
        }
        fetchData();
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCaptionById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/bills/captionfilter/', {
                        id: searchIDofelem
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setCaptions(response.data);
                        fetchData();
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setCaptions([]);
                setOffset(0);
                setHasMore(true);
                fetchCaptions();
            }
        };

        fetchCaptionById();
    }, [searchIDofelem]);

    const handleUpdateCaptions = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedCaptions.map(id => 
                api.patch(`api/v1/bills/caption/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setCaptions([]);
            fetchCaptions();
            setOffset(0);
            setHasMore(true);
            setCheckedCaptions([]);
            // setCompanies([]);
            // setCaptionTypes([]);
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} Captions`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveCaptions = () => handleUpdateCaptions(false);
    const handleUnarchiveCaptions = () => handleUpdateCaptions(true);
    

    const handleCheckboxChange = (captionId) => {
        setCheckedCaptions(prevCaptions => {
            if (prevCaptions.includes(captionId)) {
                return prevCaptions.filter(id => id !== captionId);
            } else {
                return [...prevCaptions, captionId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allCaptions = captions.map(caption => caption.id);
            setCheckedCaptions(allCaptions);
        } else {
            setCheckedCaptions([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = captions.length > 0 && captions.every(caption => checkedCaptions.includes(caption.id));


    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSearch = async () => {
        try {
            const response = await api.post('/api/v1/bills/captionfilter/', {
                name: nameFilter.toLowerCase(), 
                type : captiontype
            });
            console.log('Counterparties fetched:', response.data); 
            setCaptions(response.data)
        } catch (error) {
            console.error('Error fetching Counterparties:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Статьи</h3></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование статьи</label>
                                            <input
                                                type="text"
                                                placeholder="Введиет наименование статьи"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Тип статьи</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="api/v1/bills/caption-types/search/"
                                            value={captiontype}
                                            onChange={(value) => setselectedcaptiontype(value)}
                                            placeholder="Выберите тип статьи"
                                        />
                                        </div>
                                        
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <button 
                                            onClick={handleArchiveCaptions} 
                                            className="btn btn-secondary me-2"
                                            disabled={checkedCaptions.length === 0}
                                        >
                                            Архивировать выбранные
                                        </button>
                                        <button 
                                            onClick={handleUnarchiveCaptions} 
                                            className="btn btn-success me-2"
                                            disabled={checkedCaptions.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </button>
                                        <Link to='/create-caption' className='btn btn-primary me-2'>Добавить статью</Link>
                                        <button className="btn btn-primary" onClick={fetchCaptions}>Обновить</button>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input 
                                                    type="checkbox" 
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Наименование</th>
                                            <th>Описание</th>
                                            <th>Тип Статьи</th>
                                            <th>Родитель</th>
                                            <th>Организация</th>
                                            <th>Архивный</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {captions.map((caption) => (
                                            <tr key={caption.id} onDoubleClick={() => handleRowClick(caption)}>
                                                <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedCaptions.includes(caption.id)}
                                                        onChange={() => handleCheckboxChange(caption.id)}
                                                    />
                                                </td>
                                                <td>{caption.name}</td>
                                                <td>{caption.description}</td>
                                                <td>{caption.type_of_caption ? caption_types.find(caption_type => caption_type.id === caption.type_of_caption)?.name : ''}</td>
                                                <td>{caption.parent_caption ? captions.find(captiont => captiont.id === caption.parent_caption)?.name : ''}</td>
                                                <td>{caption.company ? companies.find(company => company.id === caption.company)?.name : ''}</td>
                                                <td>{caption.is_active ? 'Нет' : 'Да'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaptionManagement;