import React, { useState, useEffect } from 'react';
import FilterForm from '../FilterForm';
import BankAccountsTable from '../bankaccounts/BankAccountsTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 

const BankAccounts = () => {
    const [filters, setFilters] = useState({});
    const [bankaccs, setBankAccounts] = useState([]);
    
    const [nameFilter, setNameFilter] = useState('');
    const [nubmerFilter, setnumberFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no


    const fetchBankAccounts = async () => {
        try {
            const response = await api.get('api/v1/bank/allbankaccounts/');
            setBankAccounts(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error);
        }
    };

    useEffect(() => {
        fetchBankAccounts();
    }, [filters]);

    const handleFilterChange = (key, value) => {
        if (key === 'reset') {
            setFilters({});
        } else {
            setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
        }
    };
    const handleReset = () => {
        window.location.reload();

    };
    const handleSearch = async () => {
      
        try {
            const response = await api.post('api/v1/bank/bankaccountsfilter/', {
                name: nameFilter.toLowerCase(), 
                number_of_check: nubmerFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                company : organFilter
            });
            console.log('Employees fetched:', response.data); 
           
            setBankAccounts(response.data);
        } catch (error) {
            console.error('Error fetching bankaccounts:', error);
        }
    };
    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Банковские счета</h3></div>
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Название счета</label>
                                            <input
                                                type="text"
                                                placeholder="Введите название счета"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>Номер счета</label>
                                            <input
                                                type="text"
                                                placeholder="Введите номер счета"
                                                value={nubmerFilter}
                                                onChange={e => setnumberFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>
                                        <Link to='/create-bankacc' className='btn btn-primary me-2'>Добавить</Link>
                                        <button onClick={fetchBankAccounts} className="btn btn-primary">Обновить</button></div>
                                    </div>
                               
                                <BankAccountsTable bankaccs={bankaccs} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankAccounts;
