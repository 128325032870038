import { useState, useEffect, useRef, useCallback  } from 'react';
import PaymentsTable from './PaymentsTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';

const PaymentManagement = () => {
    const [payments, setPayments] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const containerRef = useRef(null);
    const limit = 15;
    const showModal = () => {
      setIsOpen(true);
    };
  
    const hideModal = () => {
      setIsOpen(false);
    };
    const fetchPayments = async (offset) => {
        setLoading(true);
        try {
            const response = await api.get(`api/v1/bills/payments/?limit=${limit}&offset=${offset}`);
            setPayments(prevPayments => {
                const newPayments = response.data.results.filter(newPayment => 
                    !prevPayments.some(prevPayment => prevPayment.id === newPayment.id)
                );
                return [...prevPayments, ...newPayments];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching payments', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPayments([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
    }, []);

    useEffect(() => {
        if (hasMore) {
            fetchPayments(offset);
        }
    }, [offset, hasMore]);


    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);


    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="payment-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Платежи</h3>
                                    <div>
                                        <Link to='/create-payment' className='btn btn-primary me-2'>Добавить платеж</Link>
                                        <button className="btn btn-secondary me-2" onClick={showModal}>Импорт файла</button>
                                        <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                    <PaymentsTable payments={payments} />
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={hideModal} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
                <Modal.Header>
                    <Modal.Title>Hi</Modal.Title>
                </Modal.Header>
                <Modal.Body>The body</Modal.Body>
                <Modal.Footer>
                <button onClick={hideModal}>Cancel</button>
                <button>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
        
    );
};

export default PaymentManagement;
