import React, { useState, useEffect } from 'react';
import EmployeesTable from './EmployeesTable';
import api from '../../api';
import SideBar from '../SideBar';
import Header from "../Header";

const Employees = () => {
    const [employees, setEmployees] = useState([]);

    const fetchEmployees = async () => {
        try {
            const response = await api.get('/api/v1/bills/employee/');
            setEmployees(response.data);
        } catch (error) {
            console.error('Error cant find employees', error);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Физические лица</h3>
                                </div>
                                <EmployeesTable employees={employees} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
