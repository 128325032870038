import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 

const Counterparties = () => {
    const navigate = useNavigate()
    const [counterparties, setCounterparties] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedCounterparties, setCheckedCounterparties] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [iinbinFilter, setinnbinFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no
    const searchIDofelem = location.search.replace("?","");

    const fetchCounterparties = async () => {
        try {
            console.log(location)
            if (searchIDofelem){
                const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                    id:searchIDofelem,
                    is_active : [true,false]
                });
                setCounterparties(response.data);
            }else{
                const response = await api.get(`api/v1/bills/counterparty/?limit=${limit}&offset=${offset}`);
             setCounterparties(prevCounterparties => {
                const newCounterparties = response.data.results.filter(newCounterparties => 
                    !prevCounterparties.some(prevCounterparties => prevCounterparties.id === newCounterparties.id)
                );
                return [...prevCounterparties, ...newCounterparties];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
            }

           
        } catch (error) {
            console.error('Error fetching bank accounts', error);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }
    useEffect(() => {
        setCounterparties([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
        setCompanies([]);
        fetchCounterparties();
    }, [location]);

    useEffect(() => async () => {
        setLoading(true);
        try {
            if (hasMore) {
                fetchCounterparties(offset);
            }
            await Promise.all([
                fetchCompanies(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCounterpartiesById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                        id:searchIDofelem,
                        is_active : [true,false]
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setCounterparties(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setCounterparties([]);
                setOffset(0);
                setHasMore(true);
                fetchCounterparties();
            }
        };

        fetchCounterpartiesById();
    }, [searchIDofelem]);


    const handleUpdateCounterparties = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedCounterparties.map(id => 
                api.patch(`api/v1/bills/counterparty/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setCounterparties([]);
            fetchCounterparties();
            // setCompanies([]);
            setOffset(0);
            setHasMore(true);
            setCheckedCounterparties([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} Counterparties`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveCounterparties = () => handleUpdateCounterparties(false);
    const handleUnarchiveCounterparties = () => handleUpdateCounterparties(true);
    

    const handleCheckboxChange = (counterpartyId) => {
        setCheckedCounterparties(prevcounterparties => {
            if (prevcounterparties.includes(counterpartyId)) {
                return prevcounterparties.filter(id => id !== counterpartyId);
            } else {
                return [...prevcounterparties, counterpartyId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allcounterparties = counterparties.map(counterparty => counterparty.id);
            setCheckedCounterparties(allcounterparties);
        } else {
            setCheckedCounterparties([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = counterparties.length > 0 && counterparties.every(counterparty => checkedCounterparties.includes(counterparty.id));

    const handleRowClick = (counterparty) => {
        navigate(`/edit-counterparty/${counterparty.id}`)
    }
    
    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSearch = async () => {
        try {
            const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                name: nameFilter.toLowerCase(), 
                iinbin: iinbinFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                company : organFilter
            });
            console.log('Counterparties fetched:', response.data); 
            setCounterparties(response.data); 
        } catch (error) {
            console.error('Error fetching Counterparties:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Контрагенты</h3></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование контрагента</label>
                                            <input
                                                type="text"
                                                placeholder="Введите контрагента"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>БИН/ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите БИН/ИИН"
                                                value={iinbinFilter}
                                                onChange={e => setinnbinFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <button 
                                            onClick={handleArchiveCounterparties} 
                                            className="btn btn-secondary me-2"
                                            disabled={checkedCounterparties.length === 0}
                                        >
                                            Архивировать выбранные
                                        </button>
                                        <button 
                                            onClick={handleUnarchiveCounterparties} 
                                            className="btn btn-success me-2"
                                            disabled={checkedCounterparties.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </button>
                                        <Link to='/create-counterparty' className='btn btn-primary me-2'>Добавить</Link>
                                        <button onClick={fetchCounterparties} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input 
                                                    type="checkbox" 
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Наименование контрагента</th>
                                            <th>БИН/ИИН</th>
                                            <th>Организация</th>
                                            <th>Архивный</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {counterparties.map((counterparty) => (
                                            <tr key={counterparty.name} onDoubleClick={() => handleRowClick(counterparty)}>
                                                <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedCounterparties.includes(counterparty.id)}
                                                        onChange={() => handleCheckboxChange(counterparty.id)}
                                                    />
                                                </td>
                                                <td style={{ 
                                                    width: '500px', 
                                                    maxWidth: '500px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>
                                                    {counterparty.name}
                                                </td>
                                                <td>{counterparty.bin_iin}</td>
                                                <td>{counterparty.company ? companies.find(company => company.id === counterparty.company)?.name : ''}</td>
                                                <td>{counterparty.is_active ? 'Нет' : 'Да'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Counterparties;
