import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/registerStyle.css";
import logo from '../assets/pp_logo.png'
import image from '../assets/login_image.png'
import axios from 'axios';
import ReactLoading from 'react-loading';

const RegistrationForm = () => {
  // State variables for form fields
  const [formData, setFormData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // State variable for error messages
  const [errors, setErrors] = useState({});

  // Handle form input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const { username, first_name, last_name, middle_name, phone_number, email, password, confirmPassword } = formData;
    const errors = {};
    if (!username) {
      errors.username = 'Username is required';
    }
    if (!email) {
      errors.email = 'Email is required';
    }
    if (!password) {
      errors.password = 'Password is required';
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    if (!first_name) {
        errors.first_name = 'First name is required';
    }
    if (!last_name) {
        errors.last_name = 'Last name is required';
    }
    if (!middle_name) {
        errors.middle_name = 'Middle name is required';
    }
    if (!phone_number) {
        errors.phone_number = 'Phone number is required'
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Submit the form
    try {
      setLoading(true);
      const response = await axios.post('https://back.upr.kz/api/v1/worker/', formData, setTimeout(5000));
      console.log('Registration successful:', response.data);
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      setLoading(false);
      navigate('/')
    } catch (error) {
      console.error('Registration failed:', error.response.data);
      // Handle error (e.g., show error message)
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (<div className="position-absolute top-50 start-50 translate-middle">
    <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    </div>);
}

  return (
    <div className='container_reg'>
      <div className='registration_card'>
        <div className="registration_header">
          <img src={logo} alt="pplogo"></img>
          <Link to="/login" className='btn btn-outline-primary'>Авторизация</Link>
      </div>
        <div className='registration_main'>
          <h2>Регистрация</h2>
          <form onSubmit={handleSubmit} className="registration_form">
            <div className='form_input'>
              <div className="form_column">
                <div className='mb-3'>
                  <label className='form-label'>ИИН:</label>
                  <input className="form-control" type="text" name="username" placeholder='Введите ИИН' value={formData.username} onChange={handleInputChange} />
                  {errors.username && <span>{errors.username}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Фамилия:</label>
                  <input className="form-control" type="text" name="first_name" placeholder='Введите фамилию' value={formData.first_name} onChange={handleInputChange} />
                  {errors.first_name && <span>{errors.first_name}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Имя:</label>
                  <input className="form-control" type="text" name="last_name" placeholder='Введите имя' value={formData.last_name} onChange={handleInputChange} />
                  {errors.last_name && <span>{errors.last_name}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Отчество:</label>
                  <input className="form-control" type="text" name="middle_name" placeholder='Введите отчество' value={formData.middle_name} onChange={handleInputChange} />
                  {errors.middle_name && <span>{errors.middle_name}</span>}
                </div>
              </div>
              <div className="form_column">
                <div className='mb-3'>
                  <label className='form-label'>Номер телефона:</label>
                  <input className="form-control" type="text" name="phone_number" placeholder='+7 777 77 77 777' value={formData.phone_number} onChange={handleInputChange} />
                  {errors.phone_number && <span>{errors.phone_number}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Email:</label>
                  <input className="form-control" type="email" name="email" placeholder='Введите email' value={formData.email} onChange={handleInputChange} />
                  {errors.email && <span>{errors.email}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Пароль:</label>
                  <input className="form-control" type="password" name="password" value={formData.password} onChange={handleInputChange} />
                  {errors.password && <span>{errors.password}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Повторить пароль:</label>
                  <input className="form-control" type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} />
                  {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
                </div>
              </div>
            </div>
            <button type="submit">Зарегистрироваться</button>
          </form>
        </div>
      </div>
      <div className='image_card'>
        <img src={image} alt="login_image"></img>
      </div>
    </div>
  );
};

export default RegistrationForm;
