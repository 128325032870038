import { useState, useEffect } from "react";
import api from "../../api";


const EmployeesTable = ({ employees }) => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCompanies()
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>ФИО физического лица</th>
                    <th>ИИН</th>
                    <th>Организация</th>
                    <th>Архивный</th>
                </tr>
            </thead>
            <tbody>
                {employees.map((employee) => (
                    <tr key={employee.name} >
                        <td>{employee.name}</td>
                        <td>{employee.iin}</td>
                        <td>{employee.company ? companies.find(company => company.id === employee.company)?.name : ''}</td>
                        <td>{employee.is_active ? 'Нет' : 'Да'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default EmployeesTable;
