import SideBar from "../SideBar";
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import Header from "../Header";


const CreateProject = () => {
    const [formData, setFormData] = useState({
        name: '',
        full_name: '',
        description: '',
        group: '',
        company: '',
      });
    
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate()


    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }

    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchGroups(),
                fetchCompanies(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await api.post('/api/v1/organization/projects/create/', formData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log('Response:', response.data);
          navigate('/projects')
        } catch (error) {
            alert('Заполните все поля')
            console.error('Error:', error);
        }
      };
    
      if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <>
        <div className="container-fluid">
        <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-4">
                    <div className="create-header">
                        <h1>Добавление нового проекта</h1>
                    </div>
                    <div className="create-bankaccount-content">
                        <form className="bankaccount-form" onSubmit={handleSubmit}>
                            <div className='form_input'>
                                <div className="row g-3">
                                    <div className='col-md-3'>
                                    <label className='form-label'>Наименование</label>
                                    <input className="form-control" type="text" name="name" placeholder='Наименование' onChange={handleChange}/>
                                    </div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Полное наименование</label>
                                    <input className="form-control" type="text" name="full_name" onChange={handleChange} placeholder='Полное наименование'/>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                    <label className='form-label'>Описание</label>
                                    <input className="form-control" type="text" name="description" onChange={handleChange} placeholder='Введите вид счета'/>
                                    </div>
                                    <div className='col-md-3'>
                                    </div>
                                    <div className="col-md-3"></div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Родительская группа в фин.моделе</label>
                                        <select
                                            className="form-select"
                                            value={formData.group || ''}
                                            name="group"
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.group ? groups.find(group => group.id === formData.group)?.name : 'Выберите группу...'}
                                            </option>
                                            {groups.map((group) => (
                                                <option key={group.id} value={group.id}>
                                                    {group.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Организация</label>
                                        <select
                                            className="form-select"
                                            name="company"
                                            value={formData.company || ''}
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.company ? companies.find(company => company.id === formData.company)?.name : 'Выберите организацию...'}
                                            </option>
                                            {companies.map((company) => (
                                                <option key={company.id} value={company.id}>
                                                    {company.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-5"></div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default CreateProject;