// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    height: 30px;
}

.avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.profile-dropdown {
    width: 250px;
    right: 0;
}

.no-scroll {
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,gCAAgC;IAChC,wCAAwC;AAC5C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,QAAQ;AACZ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 1rem;\r\n    background-color: #fff;\r\n    border-bottom: 1px solid #e5e5e5;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.logo {\r\n    height: 30px;\r\n}\r\n\r\n.avatar {\r\n    height: 40px;\r\n    width: 40px;\r\n    border-radius: 50%;\r\n}\r\n\r\n.profile-dropdown {\r\n    width: 250px;\r\n    right: 0;\r\n}\r\n\r\n.no-scroll {\r\n    overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
