import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../styles/header.css";
import logo from '../assets/pp_logo.png'
import avatar from '../assets/avatar.png'
import {jwtDecode} from 'jwt-decode';
import api from "../api";
import 'bootstrap-icons/font/bootstrap-icons.css';

const Header = () => {
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const [isSearching, setIsSearching] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const searchRef = useRef(null);
    const profileRef = useRef(null);
    const navigate = useNavigate();

    const handleProfileClick = () => {
        setIsProfileOpen(!isProfileOpen);
        fetchGroups();
    };

    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching groups', error);
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!searchQuery.trim()) return;

        setIsSearching(true);
        try {
            const response = await api.get(`/api/v1/search/?q=${encodeURIComponent(searchQuery)}`);
            setSearchResults(response.data);
            setShowSearchResults(true);
        } catch (error) {
            console.error('Error performing search:', error);
        } finally {
            setIsSearching(false);
        }
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowSearchResults(false);
        }
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setIsProfileOpen(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserData(decodedToken);
        }

        setLoading(false);

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (loading) {
        return <div><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</div>;
    }

    const userGroups = groups.filter(group => 
        group.user.some(user => user.id === userData.user_id)
    );

    const renderSearchResults = () => {
        const hasResults = Object.values(searchResults).some(category => 
            Array.isArray(category.data) && category.data.length > 0
        );

        if (!hasResults) {
            return (
                <div className="search-results position-absolute w-100 mt-1 p-2 bg-white border rounded shadow-sm">
                    <p className="text-center mb-0">Ничего не найдено</p>
                </div>
            );
        }

        return (
            <div className="search-results position-absolute w-100 mt-1 p-2 bg-white border rounded shadow-sm">
                {Object.entries(searchResults).map(([categoryKey, category]) => {
                    if (!Array.isArray(category.data) || category.data.length === 0) {
                        return null;
                    }
                    return (
                        <div key={categoryKey}>
                            <h6 className="mt-2 mb-1">{category.name}</h6>
                            <ul className="list-unstyled mb-0">
                                {category.data.map((item, index) => (
                                    <li key={index} className="py-1">
                                        <Link to={`/${categoryKey.toLowerCase()}`} className="text-decoration-none text-dark">
                                            {item.name || item.code || `${item.id}`}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <header className="header d-flex justify-content-between align-items-center p-3 bg-white border-bottom shadow-sm">
            <div className="d-flex align-items-center">
                <img src={logo} alt="Logo" className="logo me-2" />
                <div ref={searchRef} className="position-relative">
                    <form onSubmit={handleSearch} className="d-flex ms-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Глобальный поиск..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="submit" className="btn btn-outline-secondary ms-2" disabled={isSearching}>
                            {isSearching ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                <i className="bi bi-search"></i>
                            )}
                        </button>
                    </form>
                    {showSearchResults && renderSearchResults()}
                </div>
            </div>
            <div ref={profileRef} className="position-relative">
                <button 
                    onClick={handleProfileClick} 
                    className="btn btn-link text-decoration-none text-dark"
                >
                    <i class="bi bi-bell-fill"></i>
                    <img src={avatar} alt="User Avatar" className="avatar me-2" />
                    <span>{userData.first_name + ' ' + userData.last_name}</span>
                    <i class="bi bi-chevron-down"></i>
                </button>
                {isProfileOpen && (
                    <div className="profile-dropdown position-absolute end-0 mt-2 p-3 bg-white border rounded shadow-sm">
                        <div className="d-flex align-items-center mb-2">
                            <img src={avatar} alt="User Avatar" className="avatar me-2" />
                            <div>
                                <p className="mb-0">{userData.first_name + ' ' + userData.last_name}</p>
                                <p className="mb-0">{userData.email}</p>
                                <button className="btn btn-sm btn-secondary mt-1">Изменить</button>
                            </div>
                        </div>
                        <p>Фин. модели:</p>
                        <ul className="list-unstyled">
                            {userGroups.map(group => (
                                <li key={group.id}>
                                    <i className="bi bi-gear-fill"></i>
                                    <Link to={`/finmodel`} className="text-decoration-none text-dark">
                                        {group.name}
                                    </Link>
                                </li>
                            ))}
                            <li><Link to="/finmodel" className="text-decoration-none text-dark">+ Добавить фин. модель</Link></li>
                        </ul>
                        <hr />
                        <button className="btn btn-sm btn-outline-secondary">
                            <Link to="/logout" className="text-decoration-none text-dark">Выйти</Link>
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
