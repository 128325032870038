import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../api";


const CounterpartiesTable = ({ counterparties }) => {
    const navigate = useNavigate()
    const [currency, setCurrency] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleRowClick = (counterparty) => {
        navigate(`/edit-counterparty/${counterparty.id}`)
    }
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    const fetchCurrency = async () => {
        try {
            const response = await api.get('/api/v1/bank/currencies/');
            setCurrency(response.data);
        } catch (error) {
            console.log('Error fetching document types', error)
        }
    }
    
    const fetchBanks = async () => {
        try {
            const response = await api.get('/api/v1/bank/');
            setBanks(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchBanks(),
                fetchCompanies(),
                fetchCurrency(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Наименование контрагента</th>
                    <th>БИН/ИИН</th>
                    <th>Организация</th>
                    <th>Архивный</th>
                </tr>
            </thead>
            <tbody>
                {counterparties.map((counterparty) => (
                    <tr key={counterparty.name} onDoubleClick={() => handleRowClick(counterparty)}>
                        <td style={{ 
                            width: '500px', 
                            maxWidth: '500px', 
                            whiteSpace: 'normal', 
                            wordWrap: 'break-word' 
                        }}>
                            {counterparty.name}
                        </td>
                        <td>{counterparty.bin_iin}</td>
                        <td>{counterparty.company ? companies.find(company => company.id === counterparty.company)?.name : ''}</td>
                        <td>{counterparty.is_active ? 'Нет' : 'Да'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default CounterpartiesTable;
