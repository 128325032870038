import { useState, useEffect } from "react";
import api from "../api";
import SideBar from "./SideBar";
import Header from "./Header";
import { LineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer, Tooltip } from "recharts";

function Home() {
    const [bankBalances, setBankBalances] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [selectedName, setSelectedName] = useState(null);

    const fetchBankBalances = async () => {
        setLoading(true);
        try {
            const response = await api.get('api/v1/bank/bankaccount_balances/');
            setBankBalances(response.data);
            if (response.data.length > 0) {
                setSelectedName(response.data[0]?.bankaccount?.name)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(()=>{
        fetchBankBalances();
    },[])

    const fetchSelectedAccount = async () => {
        try {
            const response = await api.get(`api/v1/bank/bankaccount_balances/retrieve/?account_name=${selectedName}`);
            setSelectedAccount(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const handleRowClick = (account) => {
        setSelectedName(account)
    }

    useEffect(() => {
        fetchSelectedAccount()
    },[selectedName, setSelectedName])


    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
            <SideBar/> 
                <div className="col py-3">
                    <h3>Рабочий стол</h3>
                    {!loading ? (<div className="row">
                        <div className="col">
                            <h5 className="my-4">Банковские остатки</h5>
                            <table className="table table-borderless table-sm">
                                <thead>
                                    <tr>
                                        <th className="text-start" style={{width: '35%'}}>Остатки на счетах</th>
                                        <th className="text-start" style={{width: '35%'}}>Сумма</th>
                                        <th className="text-start" style={{width: '30%'}}>Дата обновления</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankBalances.map((balance) => (
                                        <tr key={balance.id} className={balance?.bankaccount?.name === selectedName ? 'table-primary' : ''} onClick={() => {handleRowClick(balance?.bankaccount?.name)}}>
                                            <td className="text-start">{balance?.bankaccount?.name}</td>
                                            <td className="text-start">{balance?.sum} {balance?.currency?.letter_code}</td>
                                            <td className="text-start">{balance.created}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <p className="my-4 text-center">Динамика остатков на счетах</p>
                            <ResponsiveContainer width='100%' height='72%'>
                                <LineChart 
                                    data={selectedAccount}
                                    margin={{ top: 0, right: 20, left: 20, bottom: 0 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="created" />
                                        <YAxis/>
                                        <Tooltip />
                                        <Line type="monotone" dataKey="sum" stroke="#8884d8" activeDot={{ r: 8 }}/>
                                    </LineChart>
                            </ResponsiveContainer>
                            <p className="mt-3 text-center">Ежедневное время обновления банковских счетов - 03:00</p>
                        </div>
                    </div>) : ''}
                </div>
            </div>
        </div>
    );
}

export default Home;
